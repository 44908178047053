export const devnetRPC = "https://api.devnet.solana.com";
export const mainnetRPC = "https://virulent-few-road.solana-mainnet.quiknode.pro/508a0573b7afa6416d7a43f36f8121d12bc1e28f";
export const WsAddress_DEV = "ws://localhost:4005";
export const WsAddress_PROD = "wss://api.servica.io:4005";

export const showTokensDropdown = false;
export const showCreditCardOption = false;

export const eCurrencyType = {
  SOL: "SOL",
  DUST: "DUST",
  CRECK: "CRECK",
  FORGE: "FORGE",
  SKT: "SKT",
  USDC: "USDC",
  SKBC: "SKBC",
  SCRAP: "SCRAP",
  PYJ: "PYJ",
  LINX: "LINX",
  DCODE: "DCODE",
  INK: "INK",
  RAFFL: "RAFFL",
  NEON: "NEON",
};

const SPLTOKENS_MAP = new Map();
SPLTOKENS_MAP.set(eCurrencyType.SKT, "SKTsW8KvzopQPdamXsPhvkPfwzTenegv3c3PEX4DT1o");
SPLTOKENS_MAP.set(eCurrencyType.CRECK, "Ao94rg8D6oK2TAq3nm8YEQxfS73vZ2GWYw2AKaUihDEY");
SPLTOKENS_MAP.set("CRECK_D", "ASxC3n3smkcUkA7Z58EUKZ2NfHoQ8eZrkTRK7ergYr2a");
SPLTOKENS_MAP.set(eCurrencyType.DUST, "DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ");
SPLTOKENS_MAP.set("DUST_D", "8kY8hSzXbD9uwmNtsDtRmSxQhbVwAK22kaJVbWzvQwn3");
SPLTOKENS_MAP.set(eCurrencyType.FORGE, "FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds");
SPLTOKENS_MAP.set(eCurrencyType.USDC, "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v");
SPLTOKENS_MAP.set(eCurrencyType.SOL, "11111111111111111111111111111111");
SPLTOKENS_MAP.set(eCurrencyType.SKBC, "PXWNGZXNUQfnaa5eKhmRW7rBrUKWTW86KwyFRnGMuwR");
SPLTOKENS_MAP.set(eCurrencyType.SCRAP, "6naWDMGNWwqffJnnXFLBCLaYu1y5U9Rohe5wwJPHvf1p");
SPLTOKENS_MAP.set(eCurrencyType.PYJ, "3bYb7U7ofZzXraVUz28DpqwQG127NW3nFVoiGtC4PrH6");
SPLTOKENS_MAP.set(eCurrencyType.LINX, "6ajDhJoo1kxcEredyJWYrcq4tCQW4gRywB7LLMNtuw2U");
SPLTOKENS_MAP.set(eCurrencyType.DCODE, "7xxWqi7287GAk9eA7LDPF9c1WoAWoGLDdcQSnHz2hz6A");
SPLTOKENS_MAP.set(eCurrencyType.INK, "inkDs2PsMDNHcjrqZGeuBb8LzwdQzQdxaf4JoUj7V5a");
SPLTOKENS_MAP.set(eCurrencyType.RAFFL, "RAfFL9fhoqyF7ETNdP2X67jnHg4NK9212PSmao4Fgsh");
SPLTOKENS_MAP.set(eCurrencyType.NEON, "5DurkMnR3pZgA9ApWAVtg14PoV94zU4J5s6AsgK6bVcQ");

export const DEBUG_WALLET_ADDRESS = process.env.REACT_APP_DEBUG_WALLET_ADDRESS || null;

export const SPLTOKENS_MAP_GET_TOKEN_NAME = (tokenAddress) => {
  // @ts-ignore
  const res = [...SPLTOKENS_MAP].find(([_tokenName, _tokenAddress]) => _tokenAddress === tokenAddress);

  return res
    ? { tokenName: res[0], tokenAddress: res[1] }
    : { tokenName: "", tokenAddress: "" };
};

export const WALLET_OPTION = {
  solWallet: true,
  ethWallet: false,
};
