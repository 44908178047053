import React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import DisconnectImg from "../../assets/disconnect.svg";
import AccountImg from "../../assets/account.svg";
import "./ConnectWalletButton.scss";
const ConnectWalletButton = () => {
  const { publicKey, disconnecting } = useWallet();

  const shortPubkey = (pubkey) => {
    const start = pubkey.substring(0, 4);
    const end = pubkey.substring(pubkey.length - 4);
    return `${start}...${end}`;
  };
  return (
    <div className="connect-wallet-button">
      {publicKey &&
        (disconnecting ? (
            <div></div>
          // <WalletMultiButton>
          //   <div className="wallet-button-connected">Disconnecting...</div>
          // </WalletMultiButton>
        ) : (
          <WalletMultiButton>
            <div className="wallet-button-connected">
              <img className="account-img" src={AccountImg} alt="account-img" />
              <span className="connect-wallet-address">
                {shortPubkey(publicKey.toString())}
              </span>
              <img
                className="disconnect-wallet-img"
                src={DisconnectImg}
                alt="disconnect-wallet"
              />
            </div>
          </WalletMultiButton>
        ))}
    </div>
  );
};

export default ConnectWalletButton;
