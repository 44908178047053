import React from "react";

import { createConfig, WagmiConfig } from "wagmi";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { arbiMainnet, arbiTestnet } from "./chains/chains";
import Router from "./routes";
import "./App.scss";

function EthApp() {
  const client = createConfig(
      getDefaultConfig({
      appName: "W3Pay",
      chains: [arbiMainnet, arbiTestnet],
    })
  );
  return (
    <WagmiConfig client={client}>
      <ConnectKitProvider theme="midnight">
        <Router />
      </ConnectKitProvider>
    </WagmiConfig>
  );
}

export default EthApp;
