import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ConnectWalletButton from "../components/ConnectWalletButton";
import ConnectEthWalletButton from "../components/ConnectEthWalletButton";
import BetPanel from "../components/BetPanel";
import SolBetPanel from "../components/SolBetPanel";
import EthBetPanel from "../components/EthBetPanel";
import { WALLET_OPTION } from "../config/constants";

const HomePage = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [params, setParams] = useState({
    poolId: "",
    guildId: "",
    poolAddress: "",
    betAmount: "",
    userId: "",
    avatar: "",
    userName: "",
    access_token: "",
    gameName: "Horse Race"
  });

  useEffect(() =>
  {
    if (search) {
      setParams({
        ...params,
        poolId: searchParams.get("poolId"),
        guildId: searchParams.get("guildId"),
        poolAddress: searchParams.get("poolAddress"),
        betAmount: searchParams.get("betAmount"),
        userId: searchParams.get("userId"),
        avatar: searchParams.get("avatar"),
        userName: searchParams.get("userName"),
        access_token: searchParams.get("access_token"),
      });
    }
  }, [search]);

  useEffect(() =>
  {
    if (params && params.poolId && params.guildId) {
      console.log("------ params ------");
      console.log(params);
      console.log("------ params ------");
    }
  }, [params]);

  return (
    <div className="App">
      {WALLET_OPTION.solWallet && !WALLET_OPTION.ethWallet && (
        <>
          <ConnectWalletButton />
          <SolBetPanel props={params} />
        </>
      )}
      {!WALLET_OPTION.solWallet && WALLET_OPTION.ethWallet && (
        <>
          <ConnectEthWalletButton />
          <EthBetPanel />
        </>
      )}
      {WALLET_OPTION.solWallet && WALLET_OPTION.ethWallet && (
        <>
          <ConnectWalletButton />
          <ConnectEthWalletButton />
          <BetPanel />
        </>
      )}
    </div>
  );
};

export default HomePage;
