import React from "react";
import SolApp from "./SolApp";
import EthApp from "./EthApp";
import SolEthApp from "./SolEthApp";

import { WALLET_OPTION } from "./config/constants";
function App() {
  if (WALLET_OPTION.solWallet && !WALLET_OPTION.ethWallet) {
    return <SolApp />;
  } else if (!WALLET_OPTION.solWallet && WALLET_OPTION.ethWallet) {
    return <EthApp />;
  } else if (WALLET_OPTION.solWallet && WALLET_OPTION.ethWallet) {
    return <SolEthApp />;
  } else {
    return <SolEthApp />;
  }
}

export default App;
