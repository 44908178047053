import React, { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useAccount } from "wagmi";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import LoadingSpin from "react-loading-spin";
import { Tooltip } from "react-tooltip";
import WalletImg from "../../assets/wallet_black.svg";
import "react-tooltip/dist/react-tooltip.css";
import "./index.scss";

const DepositButton = (props) => {
  const { isProcessing, tokenValue, handleClickDeposit, balance, walletTo } = props;
  const { publicKey, connecting } = useWallet();
  const [isOverValue, setIsOverValue] = useState(false);
  const [isWalletDestinationNotDefined, setIsWalletDestinationNotDefined] = useState(false);
  useEffect(() =>
  {
      Number(tokenValue) > balance ? setIsOverValue(true) : setIsOverValue(false);
      setIsWalletDestinationNotDefined(props.poolId > 0 && !props.walletTo);
  }, [tokenValue, balance, walletTo]);
  return (
    <>
      {publicKey ? (
        <button
          data-tooltip-id={`pixel-tooltip`}
          className={`deposit-button ${isProcessing || tokenValue === "" || Number(tokenValue) === 0 ? "disabled" : (isOverValue || isWalletDestinationNotDefined) ? "over-value disabled" : ""}`}
          onClick={!isProcessing && tokenValue !== "" && Number(tokenValue) !== 0 && (!isOverValue && !isWalletDestinationNotDefined) ? handleClickDeposit : () => {}}
          >
          {isProcessing ? (
            <>
              Processing...
              <LoadingSpin
                duration="2s"
                width="2px"
                timingFunction="linear"
                direction="alternate"
                size="14px"
                primaryColor="#1C212D"
                secondaryColor="#c6b246"
                numberOfRotationsInAnimation={2}
              />
            </>
          ) : (
            <>DEPOSIT</>
          )}
        </button>
      ) : connecting ? (
        <div className="deposit-button-connect-wallet">
          <WalletMultiButton>
            <div className="deposit-wallet-button">Connecting...</div>
          </WalletMultiButton>
        </div>
      ) : (
        <div
          className={`deposit-button-connect-wallet ${
            false ? "disabled" : ""
          }`}
        >
          <WalletMultiButton>
            <div className="deposit-wallet-button">
              <img src={WalletImg} alt="wallet-img" />
              Connect Wallet (SOL)
            </div>
          </WalletMultiButton>
        </div>
      )}
      {isOverValue && (
        <Tooltip id={`pixel-tooltip`} place="bottom">
          Not enough funds to deposit
        </Tooltip>
      )}
      {isWalletDestinationNotDefined && (
          <Tooltip id={`pixel-tooltip`} place="bottom">
            No destination wallet found
          </Tooltip>
      )}
    </>
  );
};

export default DepositButton;
