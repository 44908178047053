import {toast} from "react-toastify";
import {Transaction} from "@solana/web3.js";
import {WsAddress_DEV, WsAddress_PROD} from "../config/constants";

const WebSocket = require("rpc-websockets").Client;
const WsAddress = window.location.hostname === "localhost" ? WsAddress_DEV : WsAddress_PROD;
const WS_TRANSFER_SOL = "transferSOL";
const WS_VALIDATE_BETPOOL = "validateBetPool";
const WS_REPORT_TRANSACTION = "reportTransaction";

const ws = new WebSocket(WsAddress, {autoconnect: false});
const connectWebSocket = async () =>
{
    if (!ws.ready)
    {
        console.log("Connecting", WsAddress);
        console.log("WS Ready:", ws.ready);
        ws.connect();

        await new Promise((resolve, reject) =>
        {
            ws.once("open", function ()
            {
                console.log("WS Socket is now opened");

                resolve(true);
            });

            ws.once("error", function (error)
            {
                console.log("socket error", error);
                ws.close();
                toast.error("Problem connecting to RPC, try again later.");
                reject();
            });
        });
    }

    console.log("WS Ready:", ws.ready);

    return ws;
}

export const depositHorseBetTransaction = async (connection, poolId, userId, userName, walletFrom, walletTo, tokenAmount, selectedTokenData) =>
{
    const ws = await connectWebSocket();

    const isPoolValidated = await ws.call(WS_VALIDATE_BETPOOL, [{poolId, userId, userName, walletFrom: walletFrom.publicKey}])
    console.log("isPoolValidated:", isPoolValidated);

    if (!isPoolValidated || isPoolValidated.error)
    {
        console.error(`Pool ${poolId} validation failed.`);
        console.error("  => Reason:", isPoolValidated?.error_message);
        //toast.error(isPoolValidated?.error_message);

        if (isPoolValidated?.error_message?.includes("expired"))
        {
            throw new Error(`Pool ${poolId} has already expired.`);
        }
        else if (isPoolValidated?.error_message?.includes("reached maxBets"))
        {
            throw new Error(`Pool ${poolId} has reached maxBets.`);
        }
        else if (isPoolValidated?.error_message?.includes("is already in Bet Pool"))
        {
            throw new Error(`User has already placed his bet.`);
        }
        else
        {
            throw new Error(`Pool ${poolId} validation failed.`);
        }
    }

    const serializedTX = await ws.call(WS_TRANSFER_SOL, [{walletFrom: walletFrom.publicKey, walletTo, tokenAmount, selectedTokenData}]);

    if (!serializedTX || serializedTX.error) {
        console.error("socketResult is empty or not base64 encoded!");
        console.error("  => Reason:", serializedTX?.error);
        throw new Error("Result is empty or not base64 encoded!");
    }

    console.log("socketResult:", serializedTX);

    const recoveredTx = Transaction.from(Buffer.from(serializedTX, "base64"));
    console.log(recoveredTx)
    // recoveredTx.add(
    //     SystemProgram.transfer({
    //         fromPubkey: walletFrom.publicKey,
    //         toPubkey: new PublicKey(walletTo),
    //         lamports: Number(0.1) * LAMPORTS_PER_SOL,
    //     })
    // );

    //recoveredTx.instructions[0].data = recoveredTx.instructions[1].data
    //const ixData = SystemInstruction.decodeTransfer(recoveredTx.instructions[0]);
    //console.log(ixData);
    //ixData.lamports = Number(0.2) * LAMPORTS_PER_SOL;
    //console.log(ixData);
    //console.log(recoveredTx)

    console.log(selectedTokenData)
    console.log(`User deposit ${tokenAmount} ${selectedTokenData.symbol} in progress....`);

    const txId = await walletFrom.sendTransaction(recoveredTx, connection);
    console.log("Done", txId);

    // NOTE: commented out below lines since servica handle confirmation of TXs
    //await connection.confirmTransaction(txId);
    //console.log("Confirmed", `https://solscan.io/tx/${txId}`);

    const response = await ws.call(WS_REPORT_TRANSACTION, [{txId, userId, userName, walletFrom: walletFrom.publicKey, walletTo, tokenAmount}]);
    console.log("reportTransaction:", response);

    //console.log(await ws.listMethods());
    //console.log(ws.eventNames());

    return txId;
}