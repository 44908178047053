import React from "react";
import { ConnectKitButton } from "connectkit";
import DisconnectImg from "../../assets/disconnect.svg";
import AccountImg from "../../assets/account.svg";
import "./ConnectEthWalletButton.scss";

const ConnectEthWalletButton = () => {
  const shortPubkey = (pubkey) => {
    const start = pubkey.substring(0, 4);
    const end = pubkey.substring(pubkey.length - 4);
    return `${start}...${end}`;
  };
  return (
    <div className="connect-wallet-button">
      {
        <ConnectKitButton.Custom>
          {({ isConnected, isConnecting, show, hide, address, ensName }) => {
            return isConnected ? (
              <button className="wallet-button-connected" onClick={show}>
                <img
                  className="account-img"
                  src={AccountImg}
                  alt="account-img"
                />
                <span className="connect-wallet-address">
                  {shortPubkey(address.toString())}
                </span>
                <img
                  className="disconnect-wallet-img"
                  src={DisconnectImg}
                  alt="disconnect-wallet"
                />
              </button>
            ) : (
              <></>
            );
          }}
        </ConnectKitButton.Custom>
      }
    </div>
  );
};

export default ConnectEthWalletButton;
