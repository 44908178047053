import React, { useMemo } from "react";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import "@solana/wallet-adapter-react-ui/styles.css";
import { createConfig, WagmiConfig } from "wagmi";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { arbiMainnet, arbiTestnet } from "./chains/chains";
import Router from "./routes";
import "./App.scss";
import { devnetRPC, mainnetRPC } from "./config/constants";

const network = WalletAdapterNetwork.Mainnet;

function SolEthApp() {
  const endpoint =
    network === WalletAdapterNetwork.Devnet ? devnetRPC : mainnetRPC;
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter({ network }),
      new SolflareWalletAdapter({ network }),
      new SolletWalletAdapter({ network }),
    ],
    [network] //eslint-disable-line
  );

  const client = createConfig(
    getDefaultConfig({
      appName: "W3Pay",
      chains: [arbiMainnet, arbiTestnet],
    })
  );

  const walletConnectionError = (error) => {
    console.log("Wallet Connection Error:", error);
  };

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WagmiConfig client={client}>
        <ConnectKitProvider theme="midnight">
          <WalletProvider
            wallets={wallets}
            onError={walletConnectionError}
            autoConnect={true}
          >
            <WalletModalProvider>
              <Router />
            </WalletModalProvider>
          </WalletProvider>
        </ConnectKitProvider>
      </WagmiConfig>
    </ConnectionProvider>
  );
}

export default SolEthApp;
