import React, { useState, useEffect } from "react";
import { ConnectKitButton } from "connectkit";
import LoadingSpin from "react-loading-spin";
import WalletImg from "../../assets/wallet_black.svg";
import "react-tooltip/dist/react-tooltip.css";
import "./index.scss";

const ConnectEthButton = (props) => {
  const { isProcessing, tokenValue, handleClickDeposit, balance, disabled } = props;

  const [isOverValue, setIsOverValue] = useState(false);
  useEffect(() => {
    Number(tokenValue) > balance ? setIsOverValue(true) : setIsOverValue(false);
  }, [tokenValue, balance]);

  return (
    <div className="connect-eth-button-container">
      <ConnectKitButton.Custom>
        {({ isConnected, isConnecting, show, hide, address, ensName }) => {
          return !isConnected ? (
            isConnecting ? (
              <button className="connect-eth-button">Connecting...</button>
            ) : (
              <button
                className={`connect-eth-button ${
                  disabled ? "disabled" : ""
                }`}
                onClick={disabled ? () => {} : show}
              >
                <img src={WalletImg} alt="wallet-img" />
                Connect Wallet (ETH)
              </button>
            )
          ) : (
            <button
              className="connect-eth-button"
              onClick={
                !isProcessing &&
                tokenValue !== "" &&
                Number(tokenValue) !== 0 &&
                !isOverValue
                  ? handleClickDeposit
                  : () => {}
              }
            >
              {isProcessing ? (
                <>
                  Processing...
                  <LoadingSpin
                    duration="2s"
                    width="2px"
                    timingFunction="linear"
                    direction="alternate"
                    size="14px"
                    primaryColor="#1C212D"
                    secondaryColor="#c6b246"
                    numberOfRotationsInAnimation={2}
                  />
                </>
              ) : (
                <>DEPOSIT</>
              )}
            </button>
          );
        }}
      </ConnectKitButton.Custom>
    </div>
  );
};

export default ConnectEthButton;
