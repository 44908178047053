import React, { useMemo } from "react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {ConnectionProvider, WalletProvider,} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, SolletWalletAdapter} from "@solana/wallet-adapter-wallets";
import "@solana/wallet-adapter-react-ui/styles.css";
import Router from "./routes";
import "./App.scss";
import { devnetRPC, mainnetRPC } from "./config/constants";

const network = WalletAdapterNetwork.Mainnet;

function SolApp() {
  const endpoint = network === WalletAdapterNetwork.Devnet ? devnetRPC : mainnetRPC;
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter({ network }),
      new SolflareWalletAdapter({ network }),
      new SolletWalletAdapter({ network }),
    ],
    [network] //eslint-disable-line
  );

  const walletConnectionError = (error) => {
    console.log("Wallet Connection Error:", error);
  };

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} onError={walletConnectionError} autoConnect={true}>
        <WalletModalProvider>
          <Router />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default SolApp;
