export const arbiMainnet = {
  id: 42161,
  name: "Arbitrum One",
  network: "Arbitrum One",
  nativeCurrency: {
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: [
        "https://arb-mainnet.g.alchemy.com/v2/Ni8uLSMbA3Ay0izmXjuJ4PLTy1qlYK1q",
      ],
    },
    public: {
      http: [
        "https://arb-mainnet.g.alchemy.com/v2/Ni8uLSMbA3Ay0izmXjuJ4PLTy1qlYK1q",
      ],
    },
  },
  blockExplorers: {
    default: {
      name: "Arbitrum Explorer",
      url: "https://arbiscan.io/",
    },
  },
};

export const arbiTestnet = {
  id: 421613,
  name: "GoerliETH",
  network: "GoerliETH",
  nativeCurrency: {
    decimals: 18,
    name: "GoerliETH",
    symbol: "GoerliETH",
  },
  rpcUrls: {
    default: {
      http: [
        "https://arb-goerli.g.alchemy.com/v2/OqlG3AX_HbuGAN6TFOZiSrZdC6INdTFr",
      ],
    },
    public: {
      http: [
        "https://arb-goerli.g.alchemy.com/v2/OqlG3AX_HbuGAN6TFOZiSrZdC6INdTFr",
      ],
    },
  },
  blockExplorers: {
    default: {
      name: "GoerliETH EVM Explorer",
      url: "https://goerli-rollup-explorer.arbitrum.io",
    },
  },
};
