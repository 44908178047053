import React, { useState } from "react";
import LoadingSpin from "react-loading-spin";
import { ToastContainer, toast } from "react-toastify";
import DepositEthButton from "../DepositEthButton";
import Dropdown from "../Dropdown";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/logo.svg";
import NFTImage from "../../assets/nft_img.svg";
import {showTokensDropdown} from "../../config/constants";

const EthBetPanel = () => {
  const gameName = "Horse Race";

  const [tokenValue, setTokenValue] = useState("");
  const [isEthProcessing, setIsEthProcessing] = useState(false);
  const [selectedTokenData, setSelectedTokenData] = useState({
    address: "",
    decimals: 0,
    value: 0,
    symbol: "USD",
  });
  const [isShowModal, setIsShowModal] = useState(false);
  const handleChangeAmountInput = (event) => {
    const amount = event.target.value;

    if (!amount || amount.match(/^\d{1,}(\.\d{0,100})?$/)) {
      setTokenValue(amount);
    }
  };

  const handleClickEthDeposit = () => {
    setIsEthProcessing(true);
    setTimeout(() => {
      setIsEthProcessing(false);
    }, 5000);
  };

  const handleSetBalance = (address, decimals, value, symbol) => {
    setSelectedTokenData({
      ...selectedTokenData,
      address: address,
      decimals: decimals,
      value: value,
      symbol: symbol,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="logo-section">
        <img src={Logo} alt="logo" />
      </div>
      <div className="app-main">
        <img className="nft-img" src={NFTImage} alt="nft-img" />
        <div className="bet-panel">
          <div className="game-name">{gameName}</div>
          <div className="bet-title">Place your bet: </div>
          <input
            className="deposit-amount"
            placeholder="Deposit Amount"
            value={tokenValue}
            onChange={handleChangeAmountInput}
          />
          <Dropdown setBalance={handleSetBalance} showTokensDropdown={showTokensDropdown}/>
          <div className="wallet-balance">
            <span>Wallet Balance : </span>
            {Math.round((selectedTokenData.value + Number.EPSILON) * 1000) /
              1000}{" "}
            {selectedTokenData.symbol}
          </div>
          <DepositEthButton
            isProcessing={isEthProcessing}
            handleClickDeposit={handleClickEthDeposit}
          />
        </div>
      </div>
      <div
        className={`loading-deposit-modal ${
          isShowModal ? "showModal" : "hiddenModal"
        }`}
      >
        <div className="loading-deposit-background" />
        <div className="loading-deposit-modal-body">
          <LoadingSpin
            duration="2s"
            width="8px"
            timingFunction="linear"
            direction="alternate"
            size="36px"
            primaryColor="#12151d"
            secondaryColor="#c6b246"
            numberOfRotationsInAnimation={2}
          />
          <div className="loading-deposit-modal-title">Waiting for Deposit</div>
          <div className="loading-deposit-modal-description">
            Please confirm the transaction in your wallet popup
          </div>
        </div>
      </div>
    </>
  );
};

export default EthBetPanel;
