import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getTokenList, getWalletBalance } from "../../config/utils";
import { DEBUG_WALLET_ADDRESS, showCreditCardOption } from "../../config/constants";
import {
  isWalletConnectedAction,
  requestUpdateWalletBalance,
  setUserWalletBalanceAction,
} from "../../redux/walletReduxSlice";
import CardImg from "../../assets/card.svg";
import "./dropdownStyle.scss";

const Dropdown = (props) => {
  const { setBalance, showTokensDropdown } = props;
  const wallet = useWallet();
  const connection = useConnection();
  const [isOpen, setIsOpen] = useState(false);
  const initialSelectedTokenData = {
    address: "So11111111111111111111111111111111111111112",
    decimals: 9,
    name: "SOL",
    symbol: "SOL",
    logoURI: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png",
    value: 0,
  };
  const [selectedTokenData, setSelectedTokenData] = useState(initialSelectedTokenData);
  const [loading, setLoading] = useState(false);
  const [tokensData, setTokensData] = useState([]);

  const anchorWallet = {
    publicKey: DEBUG_WALLET_ADDRESS ?? wallet.publicKey,
    signAllTransactions: wallet.signAllTransactions,
    signTransaction: wallet.signTransaction,
  };
  const walletReduxStore = useSelector((state) => state.walletReduxSlice);

  const dispatch = useDispatch();

  const handleClickDropdown = async () => {
    if (wallet.connected) {
      setIsOpen(!isOpen);
    }
  };

  const handleClickToken = (data) => {
    setIsOpen(false);
    setSelectedTokenData({
      ...selectedTokenData,
      address: data.address,
      decimals: data.decimals,
      name: data.name,
      symbol: data.symbol,
      logoURI: data.logoURI,
      value: data.value,
    });
  };

  useEffect(() => {
    setBalance(selectedTokenData.address, selectedTokenData.decimals, selectedTokenData.value, selectedTokenData.symbol);
  }, [selectedTokenData]);

  useEffect( () => {
    async function updateWalletBalance()
    {
        //console.log("request update: ", walletReduxStore.isUpdateWalletBalance);
        if (walletReduxStore.isUpdateWalletBalance)
        {
          const getUserWalletBalance = await getWalletBalance(connection.connection, anchorWallet?.publicKey);
          dispatch(setUserWalletBalanceAction(getUserWalletBalance));

          dispatch(requestUpdateWalletBalance(false));
        }
    }

    updateWalletBalance();

  }, [walletReduxStore.isUpdateWalletBalance]);


  useEffect(() =>
  {
    async function getData()
    {
      if (!loading)
      {
        setLoading(true);

        /* get user wallet balance and store on redux store */
        const getUserWalletBalance = await getWalletBalance(connection.connection, anchorWallet?.publicKey);
        dispatch(setUserWalletBalanceAction(getUserWalletBalance));
      }
    }

    if (wallet.connected)
    {
      const tokenList = getTokenList(walletReduxStore.userWalletBalance);
      setTokensData(tokenList);
      if (tokenList.length) {
        setSelectedTokenData({
          ...selectedTokenData,
          address: tokenList[0].address,
          decimals: tokenList[0].decimals,
          name: tokenList[0].name,
          symbol: tokenList[0].symbol,
          logoURI: tokenList[0].logoURI,
          value: tokenList[0].value,
        });
      }

      if (!walletReduxStore.isWalletConnected)
      {
        dispatch(isWalletConnectedAction(true));
      }
      getData();
    }
    else
    {
      setTokensData([]);
      dispatch(isWalletConnectedAction(false));
      setLoading(false);
    }

    return () => {
      if (!loading) {
        setLoading(true);
      }
    };
  }, [wallet, walletReduxStore]);

  return (
    <div className="dropdown-wrapper">
      <div className={`my-current-balance ${isOpen ? "open" : ""}`}>
        <div className={`content ${!wallet.connected ? "disabled" : ""}`} onClick={handleClickDropdown}>
          <div className="content-left">
            <div className="currency">
              <img src={selectedTokenData.logoURI} alt="" className="currency-icon"/>
            </div>
            <span>{selectedTokenData.name}</span>
          </div>
          {showTokensDropdown && (
              <svg
                className="dropdown-icon"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                style={{width: "1rem", height: "1rem", verticalAlign: "middle", fill: "currentcolor", overflow: "hidden"}}>
              <path d="M952.16851 329.603857l-61.405535-60.778248c-8.91402-8.840342-23.154349-9.061376-31.774681-0.517793L513.860882 597.050048 161.772942 254.480902c-8.729825-8.655124-23.12058-8.43409-32.108279 0.481977l-61.998029 61.367673c-9.025561 8.951883-9.248641 23.156396-0.517793 31.81152l430.986895 419.334507c8.691963 8.656147 23.083741 8.433066 32.108279-0.518816l61.997006-61.367673c1.418302-1.404999 2.589988-2.952238 3.572362-4.579295l356.838128-339.929016C961.306634 352.539218 961.084577 338.40736 952.16851 329.603857z"></path>
              </svg>
          )}
        </div>
        {showTokensDropdown && (<div className="dropdown">
          {tokensData?.map((token, index) => (
              <div key={index} className="dropdown-item-wrapper">
                <div
                    className="dropdown-item"
                    onClick={() => handleClickToken(token)}
                >
                  <div className="currency">
                    <img src={token.logoURI} alt="" className="currency-icon"/>
                  </div>
                  <div className="currency-balance">
                    <div className="currency-balance-name">{token.name}</div>
                    <div className="currency-balance-value">
                      {Math.round((token.value + Number.EPSILON) * 1000) / 1000}{" "}
                      {token.name}
                    </div>
                  </div>
                </div>
                <div className="dropdown-item-divider"/>
              </div>
          ))}
          {showCreditCardOption && (
              <div
                  className="dropdown-item"
                  onClick={() => handleClickToken(initialSelectedTokenData)}
              >
                <div className="currency">
                  <img src={CardImg} alt="" className="currency-icon"/>
                </div>
                <span>Credit Card</span>
              </div>
          )}
        </div>)}
        <div className="fill-blank" onClick={() => setIsOpen(false)} />
      </div>
    </div>
  );
};

export default Dropdown;
