import React, {useEffect, useState} from "react";
import LoadingSpin from "react-loading-spin";
import { ToastContainer, toast } from "react-toastify";
import DepositButton from "../DepositButton";
import DepositEthButton from "../DepositEthButton";
import Dropdown from "../Dropdown";
import {showTokensDropdown, WALLET_OPTION, WsAddress_DEV, WsAddress_PROD} from "../../config/constants";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/logo.svg";
import NFTImage from "../../assets/nft_img.svg";
import NFTImageEmpty from "../../assets/nft_img_empty.png";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {depositBetTransaction, depositHorseBetTransaction, depositTransaction} from "../../commands/deposit";
import {requestUpdateWalletBalance} from "../../redux/walletReduxSlice";
import {useDispatch} from "react-redux";

const SolBetPanel = ({props}) =>
{
    //const {poolId, guildId, poolAddress, userId, avatar, userName, gameName} = props;
    const wallet = useWallet();
    const { publicKey, connecting } = useWallet();
    const { connection } = useConnection();
    const [tokenValue, setTokenValue] = useState("");
    const [isSolProcessing, setIsSolProcessing] = useState(false);
    const [isEthProcessing, setIsEthProcessing] = useState(false);
    const [selectedTokenData, setSelectedTokenData] = useState({
        address: "",
        decimals: 0,
        value: 0,
        symbol: "USD",
    });
    const [isShowModal, setIsShowModal] = useState(false);
    const [nftImage, setNftImage] = useState(NFTImageEmpty);
    const dispatch = useDispatch();

    useEffect(() =>
    {
        if (props.userId && props.avatar) {
          setNftImage(`https://cdn.discordapp.com/avatars/${props.userId}/${props.avatar}?size=1024`);
        }

        if (props.betAmount) {
            setTokenValue(props.betAmount);
        }
    }, [props]);

    const handleChangeAmountInput = (event) =>
    {
        const amount = event.target.value;

        if (!amount || amount.match(/^\d{1,}(\.\d{0,100})?$/)) {
          setTokenValue(amount);
        }
    };

    const handleClickEthDeposit = () =>
    {
        setIsEthProcessing(true);
        setTimeout(() => {
          setIsEthProcessing(false);
        }, 5000);
    };

    // Use for horse bet deposits for now, later we need to choose between normalSolDeposit func and this one
    const handleClickSolDeposit = async () =>
    {
      try
      {
          setIsSolProcessing(true);
          setIsShowModal(true);

          const txId = await depositHorseBetTransaction(connection, props.poolId, props.userId, props.userName, wallet, props.poolAddress, tokenValue, selectedTokenData);

          setIsSolProcessing(false);
          setIsShowModal(false);
          toast.success("Transaction sent successfully.");

          dispatch(requestUpdateWalletBalance(true));
      }
      catch (e)
      {
          toast.error(`Deposit failed, ${e.message}`);
          console.error("handleClickSolDeposit() error:");
          console.error(e);

          setIsSolProcessing(false);
          setIsShowModal(false);
      }
    };

    const handleSetBalance = (address, decimals, value, symbol) =>
    {
        setSelectedTokenData({
          ...selectedTokenData,
          address: address,
          decimals: decimals,
          value: value,
          symbol: symbol,
        });
    };

    return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="logo-section">
        <img src={Logo} alt="logo" />
      </div>
      <div className="app-main">
        <div className="bet-loading">
          <img className="nft-img" src={nftImage} alt="nft-img" />
          {(nftImage === NFTImageEmpty) && <LoadingSpin
              duration="2s"
              width="3px"
              timingFunction="linear"
              direction="alternate"
              size="16px"
              primaryColor="#000000"
              secondaryColor="#ffffff"
              numberOfRotationsInAnimation={2}
          />}
        </div>
        <div className="bet-panel">
          <div className="game-name">{props.gameName}</div>
          <div className="bet-title">Place your bet: </div>
          <input className="deposit-amount" disabled={Boolean(props.betAmount)} placeholder="Deposit Amount" value={tokenValue} onChange={handleChangeAmountInput}/>
          <Dropdown setBalance={handleSetBalance} showTokensDropdown={showTokensDropdown} />
          <div className="wallet-balance">
            <span>Wallet Balance : </span>
            {Math.round((selectedTokenData.value + Number.EPSILON) * 1000) /
              1000}{" "}
            {selectedTokenData.symbol}
          </div>
          {props.poolId && props.guildId && props.poolAddress && (<div className="wallet-balance">
            <span>Pool Address : </span>
             <a style={{color: "inherit", textDecoration: "inherit"}} target="_blank" href={`https://solscan.io/account/${props.poolAddress}`}>{props.poolAddress.substring(0,6)}....{props.poolAddress.substring(props.poolAddress.length-6)}</a>
          </div>)}
          {WALLET_OPTION.solWallet && (
            <DepositButton
              isProcessing={isSolProcessing}
              tokenValue={tokenValue}
              handleClickDeposit={handleClickSolDeposit}
              balance={selectedTokenData.value}
              walletTo={props.poolAddress}
              poolId={props.poolId}
            />
          )}
        </div>
      </div>
      <div
        className={`loading-deposit-modal ${
          isShowModal ? "showModal" : "hiddenModal"
        }`}
      >
        <div className="loading-deposit-background" />
        <div className="loading-deposit-modal-body">
          <LoadingSpin
            duration="2s"
            width="8px"
            timingFunction="linear"
            direction="alternate"
            size="36px"
            primaryColor="#12151d"
            secondaryColor="#c6b246"
            numberOfRotationsInAnimation={2}
          />
          <div className="loading-deposit-modal-title">Waiting for Deposit</div>
          <div className="loading-deposit-modal-description">
            Please confirm the transaction in your wallet popup
          </div>
        </div>
      </div>
    </>
    );
};

export default SolBetPanel;
