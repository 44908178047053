import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isWalletConnected: false,
  wallet2dNftData: [],
  wallet3dNftData: [],
  userWalletBalance: null,
  stakingVaultBalance: 0,
  isUpdateWalletBalance: false,
};

export const walletReduxSlice = createSlice({
  name: "walletInfo",
  initialState,
  reducers: {
    resetWalletReduxState: () => initialState,
    isWalletConnectedAction: (state, action) => {
      state.isWalletConnected = action.payload;
    },
    setUserWalletBalanceAction: (state, action) => {
      state.userWalletBalance = action.payload;
    },
    requestUpdateWalletBalance: (state, action) => {
      state.isUpdateWalletBalance = action.payload;
    },
  },
});

/*Action creators are generated for each case reducer function*/
export const {
  isWalletConnectedAction,
  resetWalletReduxState,
  setUserWalletBalanceAction,
  requestUpdateWalletBalance
} = walletReduxSlice.actions;

export default walletReduxSlice.reducer;
