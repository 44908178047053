import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { SPLTOKENS_MAP_GET_TOKEN_NAME } from "./constants";
const tokens = require("../idl/token_list.json");
export const getTokenList = (walletTokens) => {
  let result = [];
  if (walletTokens) {
    for (const [key, value] of Object.entries(walletTokens)) {
      const tokenData = [
        ...tokens.instructions.customList,
        ...tokens.instructions.onlineList,
      ].filter((el) => el.symbol === key);
      if (tokenData.length)
      {
        const el = {
          address: tokenData[0].address || "",
          decimals: tokenData[0].decimals || 0,
          symbol: key || "",
          name: tokenData[0].name || "",
          value: value || 0,
          logoURI: tokenData[0].logoURI,
        };
        result.push(el);
      }
    }
  }

  return result;
};

export const eCurrencyType = {
  SOL: "SOL",
  DUST: "DUST",
  CRECK: "CRECK",
  FORGE: "FORGE",
  SKT: "SKT",
  USDC: "USDC",
  SKBC: "SKBC",
  SCRAP: "SCRAP",
  PYJ: "PYJ",
  LINX: "LINX",
  DCODE: "DCODE",
  INK: "INK",
  RAFFL: "RAFFL",
  NEON: "NEON",
};

export const getWalletBalance = async (connection, walletAddress) => {
  const userWalletBalance = {};

  if (!walletAddress) {
    return userWalletBalance;
  }

  userWalletBalance[eCurrencyType.SOL] =
    (await connection.getBalance(walletAddress)) / LAMPORTS_PER_SOL;

  const response = await connection.getParsedTokenAccountsByOwner(
    walletAddress,
    { programId: TOKEN_PROGRAM_ID }
  );

  response.value.forEach((accountInfo) => {
    let pubKey = accountInfo.pubkey.toBase58();
    let mint = accountInfo.account.data["parsed"]["info"]["mint"];
    let owner = accountInfo.account.data["parsed"]["info"]["owner"];
    let decimal =
      accountInfo.account.data["parsed"]["info"]["tokenAmount"]["decimals"];
    let amount =
      accountInfo.account.data["parsed"]["info"]["tokenAmount"]["amount"];

    const token = SPLTOKENS_MAP_GET_TOKEN_NAME(mint);

    if (token.tokenName) {
      userWalletBalance[token.tokenName] = amount / LAMPORTS_PER_SOL;

      //console.log(token.tokenName);
      if (token.tokenName == eCurrencyType.USDC) {
        userWalletBalance[token.tokenName] = amount / 1000000;
      }
    }
    //console.log(`mint: ${mint} | ${amount} | ${amount / LAMPORTS_PER_SOL}`);
  });

  //console.log(userWalletBalance);
  return userWalletBalance;
};
