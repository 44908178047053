import React, { useState } from "react";
import LoadingSpin from "react-loading-spin";
import { ToastContainer, toast } from "react-toastify";
import DepositButton from "../DepositButton";
import DepositEthButton from "../DepositEthButton";
import Dropdown from "../Dropdown";
import {showTokensDropdown, WALLET_OPTION, WsAddress_DEV, WsAddress_PROD} from "../../config/constants";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/logo.svg";
import NFTImage from "../../assets/nft_img.svg";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemInstruction,
  SystemProgram,
  Transaction,
  TransactionInstruction
} from "@solana/web3.js";

const WebSocket = require("rpc-websockets").Client;
const WsAddress = window.location.hostname === "localhost" ? WsAddress_DEV : WsAddress_PROD;
//console.log(WsAddress)
const ws = new WebSocket(WsAddress, { autoconnect: false });

const BetPanel = () => {
  const gameName = "Horse Race";

  const wallet = useWallet();
  const { publicKey, connecting } = useWallet();
  const { connection } = useConnection();
  const [tokenValue, setTokenValue] = useState("");
  const [isSolProcessing, setIsSolProcessing] = useState(false);
  const [isEthProcessing, setIsEthProcessing] = useState(false);
  const [selectedTokenData, setSelectedTokenData] = useState({
    address: "",
    decimals: 0,
    value: 0,
    symbol: "USD",
  });
  const [isShowModal, setIsShowModal] = useState(false);
  const handleChangeAmountInput = (event) => {
    const amount = event.target.value;

    if (!amount || amount.match(/^\d{1,}(\.\d{0,100})?$/)) {
      setTokenValue(amount);
    }
  };

  const handleClickEthDeposit = () => {
    setIsEthProcessing(true);
    setTimeout(() => {
      setIsEthProcessing(false);
    }, 5000);
  };

  const handleClickSolDeposit = async () =>
  {
    // try
    // {
    //   console.log("Connecting", WsAddress, "WS Connected:", ws.ready);
    //
    //   ws.connect();
    //   if (!ws.ready)
    //   {
    //     await new Promise((resolve, reject) => {
    //       ws.once("open", function ()
    //       {
    //         //console.log("socket is opened");
    //         //ws.subscribe("sumCalculated");
    //
    //         resolve(true);
    //       });
    //
    //       ws.once("error", function (error) {
    //         console.log("socket error", error);
    //         ws.close();
    //         toast.error("Problem connecting to RPC, try again later.");
    //         reject();
    //       });
    //     });
    //   }
    //
    //   console.log("WS Connected", ws.ready);
    //
    //   setIsSolProcessing(true);
    //   setIsShowModal(true);
    //
    //   const from = wallet.publicKey;
    //   const amount = tokenValue;
    //   const serializedTX = await ws.call("sendSol", [from, amount, selectedTokenData]);
    //   console.log("serializedTX:", serializedTX);
    //
    //   const recoveredTx = Transaction.from(Buffer.from(serializedTX, "base64"));
    //   console.log(recoveredTx)
    //   //recoveredTx.recentBlockhash = latestBlockHash.blockhash;
    //   //recoveredTx.feePayer = anchorWallet.publicKey;
    //   //transaction.sign(newMintedTokenSigner, authKP);
    //   //console.log(recoveredTx);
    //
    //   //recoveredTx.instructions[0].data = recoveredTx.instructions[1].data
    //   //const ixData = SystemInstruction.decodeTransfer(recoveredTx.instructions[0]);
    //   //console.log(ixData);
    //   //ixData.lamports = Number(0.2) * LAMPORTS_PER_SOL;
    //   //console.log(ixData);
    //   //console.log(recoveredTx)
    //
    //   console.log(selectedTokenData)
    //   console.log(`User deposit ${tokenValue} ${selectedTokenData.symbol} in progress....`);
    //
    //   const txId = await wallet.sendTransaction(recoveredTx, connection);
    //   console.log("Done", txId);
    //
    //   await connection.confirmTransaction(txId);
    //   console.log("Confirmed", `https://solscan.io/tx/${txId}`);
    //
    //   //console.log(await ws.listMethods());
    //   //console.log(ws.eventNames());
    //
    //   //setTimeout(() =>
    //   {
    //     setIsSolProcessing(false);
    //     setIsShowModal(false);
    //     toast.success("Transaction sent successfully.");
    //   } //, 5000);
    // }
    // catch (e)
    // {
    //   console.error("handleClickSolDeposit() error:");
    //   console.error(e);
    //   setIsSolProcessing(false);
    //   setIsShowModal(false);
    // }
  };

  const handleSetBalance = (address, decimals, value, symbol) => {
    setSelectedTokenData({
      ...selectedTokenData,
      address: address,
      decimals: decimals,
      value: value,
      symbol: symbol,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="logo-section">
        <img src={Logo} alt="logo" />
      </div>
      <div className="app-main">
        <img className="nft-img" src={NFTImage} alt="nft-img" />
        <div className="bet-panel">
          <div className="game-name">{gameName}</div>
          <div className="bet-title">Place your bet: </div>
          <input
            className="deposit-amount"
            placeholder="Deposit Amount"
            value={tokenValue}
            onChange={handleChangeAmountInput}
          />
          <Dropdown setBalance={handleSetBalance} showTokensDropdown={showTokensDropdown}/>
          <div className="wallet-balance">
            <span>Wallet Balance : </span>
            {Math.round((selectedTokenData.value + Number.EPSILON) * 1000) /
              1000}{" "}
            {selectedTokenData.symbol}
          </div>
          {WALLET_OPTION.solWallet && (
            <DepositButton
              isProcessing={isSolProcessing}
              tokenValue={tokenValue}
              handleClickDeposit={handleClickSolDeposit}
              balance={selectedTokenData.value}
            />
          )}
          {WALLET_OPTION.ethWallet && (
            <DepositEthButton
              isProcessing={isEthProcessing}
              handleClickDeposit={handleClickEthDeposit}
              disabled={publicKey || connecting}
            />
          )}
        </div>
      </div>
      <div
        className={`loading-deposit-modal ${
          isShowModal ? "showModal" : "hiddenModal"
        }`}
      >
        <div className="loading-deposit-background" />
        <div className="loading-deposit-modal-body">
          <LoadingSpin
            duration="2s"
            width="8px"
            timingFunction="linear"
            direction="alternate"
            size="36px"
            primaryColor="#12151d"
            secondaryColor="#c6b246"
            numberOfRotationsInAnimation={2}
          />
          <div className="loading-deposit-modal-title">Waiting for Deposit</div>
          <div className="loading-deposit-modal-description">
            Please confirm the transaction in your wallet popup
          </div>
        </div>
      </div>
    </>
  );
};

export default BetPanel;
